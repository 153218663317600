nav {
  z-index: 3;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 54px;
  font-size: 1.2rem;
  background-color: $secondaryColor;
  a {
    color: $textColor;
    background-color: $secondaryColor;
    text-decoration: none;
  }
  a.active,
  a:hover {
    color: $primaryColor;
    font-weight: bold;
  }
}

@media (min-width: 600px) {
  nav {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 1080px) {
  nav {
    justify-content: end;
    gap: 3rem;
    padding-right: 10%;
  }
}
