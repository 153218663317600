@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Source+Code+Pro:wght@100;200;300;400&display=swap");
#home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #393e46;
}
#home .hero-media {
  position: relative;
  display: grid;
  justify-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
#home .hero-media img,
#home .hero-media .hero-initials {
  grid-area: 1/1/2/2;
  z-index: 2;
  position: relative;
  margin-top: 25vh;
  border: 2px solid #00adb5;
  font-size: 8rem;
  padding: 1rem;
  -webkit-animation: move-left 1.5s 1s forwards;
          animation: move-left 1.5s 1s forwards;
}
#home .hero-media .heading-container {
  grid-area: 1/1/2/2;
  position: relative;
  margin-top: 25vh;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  left: 4rem;
  -webkit-animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
          animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
}
#home .hero-media h1 {
  margin-left: 0;
  font-size: 6rem;
  font-weight: 500;
}
#home .hero-media a {
  grid-area: 2/1/3/2;
  position: relative;
  align-self: start;
  opacity: 0;
  font-size: 8rem;
  font-weight: 200;
  text-decoration: none;
  border: none;
  color: #00adb5;
  background-color: #393e46;
  transform: rotate(90deg);
  -webkit-animation: fadein 2s ease-in-out 2s forwards, bounce 0.75s alternate 2.5s infinite;
          animation: fadein 2s ease-in-out 2s forwards, bounce 0.75s alternate 2.5s infinite;
}

@-webkit-keyframes slide {
  from {
    left: 4rem;
  }
  to {
    left: 8rem;
  }
}

@keyframes slide {
  from {
    left: 4rem;
  }
  to {
    left: 8rem;
  }
}
@media only screen and (max-width: 1200px) {
  #home .hero-media .hero-initials {
    font-size: 6rem;
    -webkit-animation: move-left 1.5s 1s forwards;
            animation: move-left 1.5s 1s forwards;
  }
  #home .hero-media .heading-container {
    left: 3.5rem;
    -webkit-animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
            animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
  }
  #home .hero-media h1 {
    margin-left: 1rem;
    font-size: 4.5rem;
  }
  @-webkit-keyframes slide {
    from {
      left: 4rem;
    }
    to {
      left: 6rem;
    }
  }
  @keyframes slide {
    from {
      left: 4rem;
    }
    to {
      left: 6rem;
    }
  }
}
@media only screen and (max-width: 900px) {
  #home .hero-media .hero-initials {
    font-size: 4rem;
    -webkit-animation: move-left 1.5s 1s forwards;
            animation: move-left 1.5s 1s forwards;
  }
  #home .hero-media .heading-container {
    left: 3rem;
    -webkit-animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
            animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
  }
  #home .hero-media h1 {
    margin-left: 0;
    font-size: 3rem;
  }
  @-webkit-keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 5rem;
    }
  }
  @keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 5rem;
    }
  }
}
@media only screen and (max-width: 640px) {
  #home .hero-media .hero-initials {
    font-size: 3.2rem;
    padding: 0.75rem;
    -webkit-animation: move-left 1.5s 1s forwards;
            animation: move-left 1.5s 1s forwards;
  }
  #home .hero-media .heading-container {
    width: 0;
    left: 3rem;
    -webkit-animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
            animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
  }
  #home .hero-media h1 {
    margin-left: 0;
    font-size: 1.5rem;
  }
  #home .hero-media a {
    font-size: 6rem;
  }
  @-webkit-keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 4rem;
    }
  }
  @keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 4rem;
    }
  }
}
@-webkit-keyframes move-left {
  from {
    right: 0;
  }
  to {
    right: 50%;
  }
}
@keyframes move-left {
  from {
    right: 0;
  }
  to {
    right: 50%;
  }
}
@-webkit-keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes bounce {
  from {
    top: 0rem;
  }
  to {
    top: 1.5rem;
  }
}
@keyframes bounce {
  from {
    top: 0rem;
  }
  to {
    top: 1.5rem;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
nav {
  z-index: 3;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 54px;
  font-size: 1.2rem;
  background-color: #222831;
}
nav a {
  color: #eeeeee;
  background-color: #222831;
  text-decoration: none;
}
nav a.active,
nav a:hover {
  color: #00adb5;
  font-weight: bold;
}

@media (min-width: 600px) {
  nav {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (min-width: 1080px) {
  nav {
    justify-content: end;
    gap: 3rem;
    padding-right: 10%;
  }
}
#about {
  display: flex;
  flex-direction: column;
}
#about .about-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
#about .about-header h1 {
  font-size: 2.5rem;
  text-align: center;
  white-space: nowrap;
}
#about .about-header div {
  width: 100%;
  height: 0px;
  border: 1px solid #00adb5;
}
@media only screen and (max-width: 640px) {
  #about .about-header {
    padding-top: 2rem;
  }
}
#about .personal-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}
#about .personal-info img {
  width: 250px;
  height: auto;
  border-radius: 50%;
  border: 3px solid #00adb5;
}
#about .personal-info p {
  padding: 1rem;
  min-width: 300px;
  max-width: 800px;
}
#about a {
  justify-self: center;
  align-self: center;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #00adb5;
  padding: 4px 56px;
  margin-bottom: 2rem;
}
#about a:hover {
  color: #222831;
  background-color: #00adb5;
}
#about .skills {
  display: grid;
  justify-items: center;
  text-align: center;
}
#about .skills h2 {
  padding: 1rem;
}
#about .skills .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
#about .skills img {
  width: 4rem;
  height: auto;
}
#about .skills .express {
  filter: invert(100%) sepia(2%) saturate(60%) hue-rotate(236deg) brightness(114%) contrast(87%);
  background-color: transparent;
  color: initial;
}

@media (max-width: 350px) {
  #about .skills .icons {
    gap: 1rem;
  }
  #about .skills img {
    width: 2.75rem;
    height: auto;
  }
}
@media (max-width: 600px) {
  #about img {
    margin-bottom: 1rem;
  }
  #about .personal-info {
    padding-bottom: 2rem;
  }
}
@media (min-width: 600px) {
  #about .personal-info {
    flex-direction: row;
    padding: 4rem;
    gap: 2rem;
    margin-left: 10%;
    margin-right: 10%;
  }
  #about .skills .icons {
    gap: 3rem;
  }
  #about .skills img {
    width: 4rem;
    height: auto;
  }
}
@media (min-width: 1080px) {
  #about .skills .icons {
    width: 90%;
    display: flex;
    justify-content: space-around;
    gap: 0;
  }
}
#projects {
  display: flex;
  flex-direction: column;
}
#projects .projects-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
#projects .projects-header h1 {
  font-size: 2.5rem;
  text-align: center;
  white-space: nowrap;
}
#projects .projects-header div {
  width: 100%;
  height: 0px;
  border: 1px solid #00adb5;
}
@media only screen and (max-width: 640px) {
  #projects .projects-header {
    padding-top: 2rem;
  }
}
#projects .projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
}
#projects .projects-container button {
  justify-self: center;
  align-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #00adb5;
  padding: 8px 72px;
  margin-bottom: 2rem;
}
#projects .projects-container button:hover {
  cursor: pointer;
  color: #222831;
  background-color: #00adb5;
}

@media only screen and (max-width: 1100px) {
  #projects .projects-container {
    gap: 5rem;
  }
  #projects .projects-container button {
    padding: 8px 48px;
  }
}
.project {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  padding: 1rem;
  width: 100%;
  max-width: 1500px;
}
.project .info {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  background-color: rgba(57, 62, 70, 0.8);
}
.project .info h2 {
  text-align: center;
  font-size: 2rem;
}
.project .info .technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem;
  margin: 0.75rem auto 1rem;
  border-top: 1px solid #222831;
  border-bottom: 1px solid #222831;
}
.project .info .technologies li:first-child {
  list-style: none;
}
.project .info p {
  padding-bottom: 1rem;
}
.project .info .features {
  padding: 1rem;
}
.project .info .features li {
  padding-bottom: 1rem;
}
.project .info .links {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
}
.project .info .links a {
  width: 100%;
  text-decoration: none;
  border: 1px solid #00adb5;
  padding: 4px 16px;
  font-weight: bold;
  text-align: center;
}
.project .info .links a:hover {
  background-color: #00adb5;
  color: #222831;
}
.project .preview-images {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.project .preview-images .image-container {
  position: relative;
}
.project .preview-images img {
  border: 1px solid #222831;
}
.project .preview-images .desktop {
  height: auto;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 0 20px #eeeeee;
  margin-left: -10px;
}
.project .preview-images .mobile {
  position: absolute;
  right: -10px;
  top: 80px;
  width: 25%;
  max-width: 150px;
  box-shadow: 0 0 20px #eeeeee;
}

.project:last-child {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1100px) {
  .project {
    flex-direction: column-reverse;
  }
  .project .info {
    max-width: 500px;
  }
  .project .preview-images .desktop {
    width: 100%;
    max-width: 600px;
  }
  .project .preview-images .mobile {
    max-width: 150px;
  }
}
#contact {
  display: flex;
  flex-direction: column;
}
#contact .contact-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
}
#contact .contact-header h1 {
  font-size: 2.5rem;
  text-align: center;
  white-space: nowrap;
}
#contact .contact-header div {
  width: 100%;
  height: 0px;
  border: 1px solid #00adb5;
}
@media only screen and (max-width: 640px) {
  #contact .contact-header {
    padding-top: 2rem;
  }
}
#contact .contact-message {
  max-width: 600px;
  padding-bottom: 2rem;
}
#contact .contact-body {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding: 1rem;
}
#contact p {
  padding: 1rem;
}
#contact form {
  display: grid;
  justify-items: center;
  gap: 8px;
}
#contact form input,
#contact form textarea {
  padding: 4px;
  margin-bottom: 0.5rem;
  width: 100%;
  max-width: 500px;
  border-radius: 0px;
  background-color: #eeeeee;
  color: #222831;
}
#contact form textarea {
  resize: none;
  height: 5rem;
  margin-bottom: 2rem;
}
#contact form input:focus,
#contact form textarea:focus {
  outline: none;
  border: 2px solid #00adb5;
}
#contact form button {
  height: 2.2rem;
  border: 1px solid #00adb5;
  padding: 4px 56px;
  font-size: 1.2rem;
  font-weight: bold;
}
#contact form button:hover {
  cursor: pointer;
  background-color: #00adb5;
  color: #222831;
}
#contact .sending {
  border: 2px solid #393e46;
  border-top: 2px solid #00adb5;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  -webkit-animation: spin 1.2s linear infinite;
          animation: spin 1.2s linear infinite;
}
#contact .confirmation {
  text-align: center;
}

@media (max-width: 640px) {
  #contact form input,
#contact form textarea {
    width: 75%;
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  height: 80px;
  background-color: #222831;
}
#footer a {
  background-color: #222831;
}
#footer img {
  width: 50px;
  height: 50px;
  filter: invert(97%) sepia(60%) saturate(434%) hue-rotate(196deg) brightness(117%) contrast(87%);
  background-color: transparent;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 54px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Code Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #393e46;
  color: #eeeeee;
}/*# sourceMappingURL=main.css.map */