#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  height: 80px;
  background-color: $secondaryColor;
  a {
    background-color: $secondaryColor;
  }
  img {
    width: 50px;
    height: 50px;
    filter: invert(97%) sepia(60%) saturate(434%) hue-rotate(196deg)
      brightness(117%) contrast(87%);
    background-color: transparent;
  }
}
