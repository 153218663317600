#contact {
  display: flex;
  flex-direction: column;
  .contact-header {
    @include section-header;
  }
  .contact-message {
    max-width: 600px;
    padding-bottom: 2rem;
  }
  .contact-body {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    padding: 1rem;
  }
  p {
    padding: 1rem;
  }
  form {
    display: grid;
    justify-items: center;
    gap: 8px;
    input,
    textarea {
      padding: 4px;
      margin-bottom: 0.5rem;
      width: 100%;
      max-width: 500px;
      border-radius: 0px;
      background-color: $textColor;
      color: $secondaryColor;
    }
    textarea {
      resize: none;
      height: 5rem;
      margin-bottom: 2rem;
    }
    input:focus,
    textarea:focus {
      outline: none;
      border: 2px solid $primaryColor;
    }
    button {
      height: 2.2rem;
      border: 1px solid $primaryColor;
      padding: 4px 56px;
      font-size: 1.2rem;
      font-weight: bold;
    }
    button:hover {
      cursor: pointer;
      background-color: $primaryColor;
      color: $secondaryColor;
    }
  }
  .sending {
    border: 2px solid $backgroundColor;
    border-top: 2px solid $primaryColor;
    border-radius: 50%;
    width: 2.2rem;
    height: 2.2rem;
    animation: spin 1.2s linear infinite;
  }
  .confirmation {
    text-align: center;
  }
}

@media (max-width: 640px) {
  #contact {
    form {
      input,
      textarea {
        width: 75%;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
