#about {
  display: flex;
  flex-direction: column;

  .about-header {
    @include section-header;
  }

  .personal-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    img {
      width: 250px;
      height: auto;
      border-radius: 50%;
      border: 3px solid $primaryColor;
    }
    p {
      padding: 1rem;
      min-width: 300px;
      max-width: 800px;
    }
  }

  a {
    justify-self: center;
    align-self: center;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border: 1px solid $primaryColor;
    padding: 4px 56px;
    margin-bottom: 2rem;
  }
  a:hover {
    color: $secondaryColor;
    background-color: $primaryColor;
  }

  .skills {
    display: grid;
    justify-items: center;
    text-align: center;
    h2 {
      padding: 1rem;
    }
    .icons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      gap: 1rem;
    }
    img {
      width: 4rem;
      height: auto;
    }
    .express {
      filter: invert(100%) sepia(2%) saturate(60%) hue-rotate(236deg)
        brightness(114%) contrast(87%);
      background-color: transparent;
      color: initial;
    }
  }
}

@media (max-width: 350px) {
  #about {
    .skills {
      .icons {
        gap: 1rem;
      }
      img {
        width: 2.75rem;
        height: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  #about {
    img {
      margin-bottom: 1rem;
    }
    .personal-info {
      padding-bottom: 2rem;
    }
  }
}
@media (min-width: 600px) {
  #about {
    .personal-info {
      flex-direction: row;
      padding: 4rem;
      gap: 2rem;
      margin-left: 10%;
      margin-right: 10%;
    }
    .skills {
      .icons {
        gap: 3rem;
      }
      img {
        width: 4rem;
        height: auto;
      }
    }
  }
}

@media (min-width: 1080px) {
  #about {
    .skills {
      .icons {
        width: 90%;
        display: flex;
        justify-content: space-around;
        gap: 0;
      }
    }
  }
}
