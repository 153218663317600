#home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $backgroundColor;
  .hero-media {
    position: relative;
    display: grid;
    justify-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img,
    .hero-initials {
      grid-area: 1 / 1 / 2 / 2;
      z-index: 2;
      position: relative;
      margin-top: 25vh;
      border: 2px solid $primaryColor;
      font-size: 8rem;
      padding: 1rem;
      animation: move-left 1.5s 1s forwards;
    }
    .heading-container {
      grid-area: 1 / 1 / 2 / 2;
      position: relative;
      margin-top: 25vh;
      overflow: hidden;
      white-space: nowrap;
      width: 0;
      left: 4rem;
      animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
    }
    h1 {
      margin-left: 0;
      font-size: 6rem;
      font-weight: 500;
    }
    a {
      grid-area: 2 / 1 / 3 / 2;
      position: relative;
      align-self: start;
      opacity: 0;
      font-size: 8rem;
      font-weight: 200;
      text-decoration: none;
      border: none;
      color: $primaryColor;
      background-color: $backgroundColor;
      transform: rotate(90deg);
      animation: fadein 2s ease-in-out 2s forwards,
        bounce 0.75s alternate 2.5s infinite;
    }
  }
}

@keyframes slide {
  from {
    left: 4rem;
  }
  to {
    left: 8rem;
  }
}

@media only screen and (max-width: 1200px) {
  #home {
    .hero-media {
      .hero-initials {
        font-size: 6rem;
        animation: move-left 1.5s 1s forwards;
      }
      .heading-container {
        left: 3.5rem;
        animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
      }
      h1 {
        margin-left: 1rem;
        font-size: 4.5rem;
      }
    }
  }
  @keyframes slide {
    from {
      left: 4rem;
    }
    to {
      left: 6rem;
    }
  }
}

@media only screen and (max-width: 900px) {
  #home {
    .hero-media {
      .hero-initials {
        font-size: 4rem;
        animation: move-left 1.5s 1s forwards;
      }
      .heading-container {
        left: 3rem;
        animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
      }
      h1 {
        margin-left: 0;
        font-size: 3rem;
      }
    }
  }
  @keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 5rem;
    }
  }
}

@media only screen and (max-width: 640px) {
  #home {
    .hero-media {
      .hero-initials {
        font-size: 3.2rem;
        padding: 0.75rem;
        animation: move-left 1.5s 1s forwards;
      }
      .heading-container {
        width: 0;
        left: 3rem;
        animation: expand 1.5s 1s forwards, slide 0.75s 1s forwards;
      }
      h1 {
        margin-left: 0;
        font-size: 1.5rem;
      }
      a {
        font-size: 6rem;
      }
    }
  }
  @keyframes slide {
    from {
      left: 3rem;
    }
    to {
      left: 4rem;
    }
  }
}

@keyframes move-left {
  from {
    right: 0;
  }
  to {
    right: 50%;
  }
}

@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes bounce {
  from {
    top: 0rem;
  }
  to {
    top: 1.5rem;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
