$primaryColor: #00adb5;
$secondaryColor: #222831;
$backgroundColor: #393e46;
$textColor: #eeeeee;

@mixin section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  h1 {
    font-size: 2.5rem;
    text-align: center;
    white-space: nowrap;
  }
  div {
    width: 100%;
    height: 0px;
    border: 1px solid #00adb5;
  }

  @media only screen and (max-width: 640px) {
    padding-top: 2rem;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Source+Code+Pro:wght@100;200;300;400&display=swap');
@import './hero';
@import './navbar';
@import './about';
@import './projects';
@import './projectCard';
@import './contact';
@import './footer';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 54px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundColor;
  color: $textColor;
}
