#projects {
  display: flex;
  flex-direction: column;
  .projects-header {
    @include section-header;
  }
  .projects-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem;
    button {
      justify-self: center;
      align-self: center;
      font-size: 1.4rem;
      font-weight: bold;
      text-decoration: none;
      border: 1px solid $primaryColor;
      padding: 8px 72px;
      margin-bottom: 2rem;
    }
    button:hover {
      cursor: pointer;
      color: $secondaryColor;
      background-color: $primaryColor;
    }
  }
}

@media only screen and (max-width: 1100px) {
  #projects {
    .projects-container {
      gap: 5rem;
      button {
        padding: 8px 48px;
      }
    }
  }
}
