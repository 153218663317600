.project {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  padding: 1rem;
  width: 100%;
  max-width: 1500px;
  .info {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: rgba(57, 62, 70, 0.8);
    h2 {
      text-align: center;
      font-size: 2rem;
    }
    .technologies {
      li:first-child {
        list-style: none;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0.5rem;
      margin: 0.75rem auto 1rem;
      border-top: 1px solid $secondaryColor;
      border-bottom: 1px solid $secondaryColor;
    }
    p {
      padding-bottom: 1rem;
    }
    .features {
      padding: 1rem;
      li {
        padding-bottom: 1rem;
      }
    }
    .links {
      display: flex;
      justify-content: space-around;
      gap: 3rem;
      a {
        width: 100%;
        text-decoration: none;
        border: 1px solid $primaryColor;
        padding: 4px 16px;
        font-weight: bold;
        text-align: center;
      }
      a:hover {
        background-color: $primaryColor;
        color: $secondaryColor;
      }
    }
  }
  .preview-images {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    .image-container {
      position: relative;
    }
    img {
      border: 1px solid $secondaryColor;
    }
    .desktop {
      height: auto;
      width: 100%;
      max-width: 600px;
      box-shadow: 0 0 20px $textColor;
      margin-left: -10px;
    }
    .mobile {
      position: absolute;
      right: -10px;
      top: 80px;
      width: 25%;
      max-width: 150px;
      box-shadow: 0 0 20px $textColor;
    }
  }
}

.project:last-child {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 1100px) {
  .project {
    flex-direction: column-reverse;
    .info {
      max-width: 500px;
    }
    .preview-images {
      .desktop {
        width: 100%;
        max-width: 600px;
      }
      .mobile {
        max-width: 150px;
      }
    }
  }
}
